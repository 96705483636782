import React from 'react';
import Helmet from 'react-helmet';

export default function HeadScripts() {
  return (
    <Helmet>
      <link
        key="preconnect-cft"
        href="https://images.ctfassets.net"
        rel="preconnect"
      />
      <link
        key="preconnect-cf"
        href="https://d2of6bhnpl91ni.cloudfront.net/"
        rel="preconnect"
      />
      <meta content="{}" id="ld-bootstrap" name="ld-bootstrap" />
    </Helmet>
  );
}
