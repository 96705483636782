/* global Sentry */

export default function logError(error, errorInfo) {
  if (Sentry) {
    if (errorInfo) {
      Sentry.configureScope((scope) => {
        Object.keys(errorInfo).forEach((key) => scope.setExtra(key, errorInfo[key]));
      });
    }
    Sentry.captureException(error);
  }
}
