import fetchUser from '@animoto/utils/fetchUser';
import getCookie from '@animoto/utils/getCookie';
import { useEffect, useState } from 'react';

/**
 * Fetches a user and returns the user object
 *
 * @returns {Object} User information
 */
export default function useUser() {
  const [user, setUser] = useState({
    isLoading : true
  });

  useEffect(() => {
    const userToken = getCookie('user_token');

    async function fetchAndSetUser() {
      const userData = await fetchUser();
      if (userData) {
        setUser(userData);
      }
    }

    if (userToken) {
      fetchAndSetUser();
    } else {
      setUser({ isLoading : false });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return user;
}
