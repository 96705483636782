const footerLinks = [
  {
    title : 'Work',
    links : [
      {
        label : 'Business',
        url   : '/business'
      },
      {
        label : 'Real estate',
        url   : '/business/real-estate-videos'
      },
      {
        label : 'Photography',
        url   : '/business/photography'
      },
      {
        label : 'Education',
        url   : '/business/education'
      },
      {
        label : 'Fitness',
        url   : '/business/fitness'
      },
      {
        label : 'Non-profit',
        url   : '/business/non-profit'
      },
      {
        label : 'Ecommerce',
        url   : '/business/ecommerce-video-marketing'
      }
    ]
  },
  {
    title : 'Personal',
    links : [
      {
        label : 'Birthday',
        url   : '/make/happy-birthday-videos'
      },
      {
        label : 'Wedding',
        url   : '/make/wedding-video'
      },
      {
        label : 'Memorial',
        url   : '/make/memorial-video'
      },
      {
        label : 'Graduation',
        url   : '/make/graduation-slideshow-video'
      },
      {
        label : 'Holidays',
        url   : '/make/holiday-video-slideshows'
      }
    ]
  },
  {
    title : 'Features',
    links : [
      {
        label : 'Video commenting tool',
        url   : '/features/video-commenting-tool'
      },
      {
        label : 'Screen recorder',
        url   : '/features/screen-recorder'
      },
      {
        label : 'Stock library',
        url   : '/features/video-maker-with-stock-library'
      },
      {
        label : 'Online video maker',
        url   : '/apps/online-video-maker'
      },
      {
        label : 'Text and fonts',
        url   : '/features/text-on-video'
      },
      {
        label : 'Logo watermark',
        url   : '/features/add-logo-watermark-to-video'
      },
      {
        label : 'Music library',
        url   : '/features/add-music-to-video'
      },
      {
        label : 'Video trimming',
        url   : '/features/crop-and-trim-video'
      },
      {
        label : 'All features',
        url   : '/features'
      }
    ]
  },
  {
    title : 'Video Types',
    links : [
      {
        label : 'Slideshow video',
        url   : '/make/slideshow-video'
      },
      {
        label : 'Recipe video',
        url   : '/make/cooking-videos'
      },
      {
        label : 'Video ads',
        url   : '/make/video-ads'
      },
      {
        label : 'Tutorial video',
        url   : '/make/tutorial-videos'
      },
      {
        label : 'Promo video',
        url   : '/business/promo-video'
      },
      {
        label : 'Marketing video',
        url   : '/make/marketing-video'
      },
      {
        label : 'Square video',
        url   : '/make/square-video-editor'
      }
    ]
  },
  {
    title : 'Social Media',
    links : [
      {
        label : 'Facebook',
        url   : '/social-media/facebook-video-maker'
      },
      {
        label : 'YouTube',
        url   : '/social-media/youtube-video-maker'
      },
      {
        label : 'Instagram',
        url   : '/social-media/instagram-video-editor'
      },
      {
        label : 'Twitter',
        url   : '/social-media/twitter-video'
      }
    ]
  }
];

export default footerLinks;
