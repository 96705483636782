import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import logo from '../images/logo.png';

function SEOComponent({ config, SEOInfo }) {
  let title;
  let description;
  let image;
  let url;

  // Set Default OpenGraph Parameters for Fallback
  title = config.siteTitle;
  description = config.siteDescription;
  url = config.siteUrl;

  image = `${url}${logo}`;

  if (SEOInfo.context && SEOInfo.context === 'blog') {
    ({ title, description } = config.blog);
  }

  // Replace with Page Parameters if post or page
  ({ title = title, description = description, image = image } = SEOInfo);

  const { slug } = SEOInfo;

  if (slug) {
    url = `${url}/${slug.replace(/^\/|\/$/, '')}`; // strip leading and trailing slashes
  }

  // Use Hero Image for OpenGraph
  if (SEOInfo.image) {
    image = `https:${SEOInfo.image}`;
  }

  // Default Website Schema
  const schemaOrgJSONLD = [
    {
      '@context'    : 'http://schema.org',
      '@type'       : 'WebSite',
      url           : config.siteUrl,
      name          : config.siteTitle,
      alternateName : config.siteTitleAlt ? config.siteTitleAlt : ''
    }
  ];

  // Blog Schema
  if (SEOInfo.context === 'blog') {
    schemaOrgJSONLD.push(
      {
        '@context'  : 'http://schema.org',
        '@type'     : 'Blog',
        name        : config.blog.title,
        url         : config.blog.url,
        description : config.blog.description,
        publisher   : {
          '@type' : 'Organization',
          name    : 'Animoto'
        }
      }
    );
  }

  // Blog Post Schema
  if (SEOInfo.type === 'blogPost') {
    schemaOrgJSONLD.push(
      {
        '@context'      : 'http://schema.org',
        '@type'         : 'BreadcrumbList',
        itemListElement : [
          {
            '@type'  : 'ListItem',
            position : 1,
            item     : {
              '@id' : config.siteUrl,
              name  : config.siteTitle
            }
          },
          {
            '@type'  : 'ListItem',
            position : 2,
            item     : {
              '@id' : url,
              name  : title
            }
          }
        ]
      },
      {
        '@context'    : 'http://schema.org',
        '@type'       : 'BlogPosting',
        url,
        name          : title,
        alternateName : '',
        headline      : title,
        image         : {
          '@type' : 'ImageObject',
          url     : image
        },
        author : {
          '@type' : 'Person',
          name    : SEOInfo.author.name,
          url     : `${config.blog.url}/${SEOInfo.author.slug}`
        },
        publisher : {
          '@type' : 'Organization',
          name    : 'Animoto',
          url     : config.siteUrl,
          logo    : `${config.siteUrl}${logo}`
        },
        datePublished    : SEOInfo.publishedOn,
        dateModified     : SEOInfo.updatedAt,
        mainEntityOfPage : url
      }
    );
  }

  if (SEOInfo.howToSchema) {
    schemaOrgJSONLD.push(SEOInfo.howToSchema);
  }

  if (SEOInfo.listSchema) {
    schemaOrgJSONLD.push(SEOInfo.listSchema);
  }

  // Hero video schema
  if (SEOInfo.heroVideoSchemaInfo) {
    const { heroVideoSchemaInfo } = SEOInfo;
    schemaOrgJSONLD.push(
      {
        '@context'   : 'http://schema.org',
        '@type'      : 'VideoObject',
        name         : heroVideoSchemaInfo.name,
        contentUrl   : heroVideoSchemaInfo.contentUrl,
        description  : heroVideoSchemaInfo.description,
        thumbnailUrl : heroVideoSchemaInfo.thumbnailUrl,
        transcript   : heroVideoSchemaInfo.transcript,
        uploadDate   : heroVideoSchemaInfo.uploadDate
      }
    );
  }

  const pageTitle = `${title} - Animoto`;

  return (
    <Helmet title={pageTitle}>
      {/* General tags */}
      <meta content={image} name="image" />
      <meta content={description} name="description" />

      {SEOInfo.noIndex ? <meta content="noindex,follow" name="robots" /> : null}

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta content={title} property="og:title" />
      {SEOInfo.type === 'blogPost' ? <meta content="article" property="og:type" /> : null}

      <meta content={url} property="og:url" />
      <meta content={image} property="og:image" />
      <meta content={description} property="og:description" />

      {/* Twitter Card tags */}
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={config.twitterHandle} name="twitter:creator" />
      <meta content={title} name="twitter:title" />
      <meta content={image} name="twitter:image" />
      <meta content={description} name="twitter:description" />

      {/* Canonical Url */}
      {SEOInfo.canonicalSlug ? <link href={`${config.siteUrl}${SEOInfo.canonicalSlug}`} rel="canonical" /> : null}
    </Helmet>
  );
}

export default function SEO(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteTitle: title
              siteDescription : description
              siteUrl
              twitterHandle,
              blog {
                url
                description
                title
              }
            }
          }
        }
      `}
      render={(config) => (
        <SEOComponent config={config.site.siteMetadata} {...props} />
      )}
    />
  );
}

SEOComponent.propTypes = {
  config : PropTypes.shape({
    blog : PropTypes.shape({
      description : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      url         : PropTypes.string.isRequired
    }).isRequired,
    siteDescription : PropTypes.string.isRequired,
    siteTitle       : PropTypes.string.isRequired,
    siteTitleAlt    : PropTypes.string.isRequired,
    siteUrl         : PropTypes.string.isRequired,
    twitterHandle   : PropTypes.string.isRequired
  }).isRequired,
  SEOInfo : PropTypes.shape({
    author : PropTypes.shape({
      name : PropTypes.string.isRequired,
      slug : PropTypes.string.isRequired
    }),
    canonicalSlug       : PropTypes.string,
    context             : PropTypes.string,
    description         : PropTypes.string,
    heroVideoSchemaInfo : PropTypes.shape({
      contentUrl   : PropTypes.string,
      description  : PropTypes.string,
      name         : PropTypes.string,
      thumbnailUrl : PropTypes.string,
      transcript   : PropTypes.string,
      uploadDate   : PropTypes.string
    }),
    howToSchema : PropTypes.shape({
      '@context'  : PropTypes.oneOf(['http://schema.org']).isRequired,
      '@type'     : PropTypes.oneOf(['HowTo']).isRequired,
      description : PropTypes.string.isRequired,
      name        : PropTypes.string.isRequired,
      step        : PropTypes.arrayOf(
        PropTypes.shape({
          '@type' : PropTypes.oneOf(['HowToStep']).isRequired,
          image   : PropTypes.string,
          name    : PropTypes.string.isRequired,
          url     : PropTypes.string.isRequired,
          video   : PropTypes.shape({
            '@type'      : PropTypes.oneOf(['VideoObject']).isRequired,
            contentUrl   : PropTypes.string.isRequired,
            description  : PropTypes.string.isRequired,
            duration     : PropTypes.string.isRequired,
            embedUrl     : PropTypes.string,
            name         : PropTypes.string.isRequired,
            thumbnailUrl : PropTypes.string.isRequired,
            uploadDate   : PropTypes.string.isRequired
          })
        })
      ).isRequired,
      supply    : PropTypes.array.isRequired,
      tool      : PropTypes.array.isRequired,
      totalTime : PropTypes.string.isRequired
    }),
    image      : PropTypes.string,
    listSchema : PropTypes.shape({
      '@context'      : PropTypes.oneOf(['http://schema.org']).isRequired,
      '@type'         : PropTypes.oneOf(['ItemList']).isRequired,
      itemListElement : PropTypes.arrayOf(
        PropTypes.shape({
          '@type' : PropTypes.oneOf(['ListItem']).isRequired,
          item    : PropTypes.shape({
            '@type' : PropTypes.oneOf(['HowToTip']).isRequired,
            image   : PropTypes.string,
            name    : PropTypes.string.isRequired,
            text    : PropTypes.string,
            url     : PropTypes.string.isRequired
          }).isRequired,
          position : PropTypes.number.isRequired
        }).isRequired
      ).isRequired
    }),
    noIndex     : PropTypes.bool,
    publishedOn : PropTypes.string,
    slug        : PropTypes.string,
    title       : PropTypes.string,
    type        : PropTypes.oneOf(['blogPost', 'blogAuthor']),
    updatedAt   : PropTypes.string
  })
};

SEOComponent.defaultProps = {
  SEOInfo : {}
};
