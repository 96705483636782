import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

/**
 * Returns if a user is in the EU region and if they have
 * accepted tracking terms
 *
 * @returns {Object} Region and consent information
 */
export default function useEUCheck() {
  const [inConsentRegion, setInConsentRegion] = useState(false);
  const [trackingAccepted, setTrackingAccepted] = useState(false);

  useEffect(() => {
    setInConsentRegion(Cookies.get('consent') === 'given' || Cookies.get('consent') === 'needed');
    setTrackingAccepted(Cookies.get('consent') === 'given');
  }, []);

  return {
    inConsentRegion,
    trackingAccepted
  };
}
