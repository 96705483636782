import { CloseIcon } from '@animoto/components';
import useLdSubscribe from '@animoto/ld-utils/useLdSubscribe';
import savePref from '@animoto/utils/hints';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';

import { siteAnnouncementKey } from '../../constants';
import { PageNotificationContext } from '../PageNotificationProvider';
import {
  closeButton,
  error,
  message as messageClass,
  notice,
  show,
  success,
  warning,
  wrap
} from './PageNotification.module.css';
import useSiteNotification from './useSiteNotification';

const autoCloseTime = 10000;

export default function PageNotification({ isLoadingUser, userId, userHints }) {
  const [data, setMessage] = useContext(PageNotificationContext);
  const siteAnnouncement = useLdSubscribe(siteAnnouncementKey, '');

  const { isOpen, message, type } = data;

  const closeMessage = useCallback(() => {
    setMessage((state) => ({
      ...state,
      isOpen : false
    }));
  }, [setMessage]);

  useSiteNotification({
    siteAnnouncement,
    isLoadingUser,
    closeMessage,
    isOpen,
    setMessage,
    type,
    userHints
  });

  const onCloseClick = useCallback(async () => {
    if (type === 'notice') {
      const timestamp = Date.now();
      window.localStorage.setItem(siteAnnouncementKey, timestamp);
      if (userId) {
        savePref(userId, siteAnnouncementKey, timestamp);
      }
    }

    closeMessage();
  }, [closeMessage, type, userId]);

  useEffect(() => {
    let timeoutId;
    if (isOpen && type !== 'notice') {
      timeoutId = setTimeout(closeMessage, autoCloseTime);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isOpen, type, closeMessage]);

  const wrapStyles = classNames(wrap, {
    [show] : isOpen
  });

  const messageStyles = classNames(messageClass, {
    [success] : type === 'success',
    [error]   : type === 'error',
    [notice]  : type === 'notice',
    [warning] : type === 'warning'
  });

  return (
    <div className={wrapStyles}>
      <div className={messageStyles}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html : message }} />
        <button className={closeButton} onClick={onCloseClick} title="Close (Esc)" type="button">
          <CloseIcon color="white" />
        </button>
      </div>
    </div>
  );
}

PageNotification.propTypes = {
  isLoadingUser : PropTypes.bool,
  userHints     : PropTypes.object, // eslint-disable-line react/forbid-prop-types
  userId        : PropTypes.number
};

PageNotification.defaultProps = {
  isLoadingUser : false,
  userId        : null,
  userHints     : {}
};
