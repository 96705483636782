export default function showSiteAnnouncement({
  announcementMessage,
  siteAnnouncementUserHint,
  announcementDate,
  isLoadingUser
}) {
  if (isLoadingUser || (announcementMessage && announcementMessage === 'default')) {
    return false;
  }
  const lastDismissalDate = parseFloat(window.localStorage.getItem('site-announcements')) || (siteAnnouncementUserHint && parseFloat(siteAnnouncementUserHint[siteAnnouncementUserHint.length - 1]));

  return (!lastDismissalDate && announcementMessage)
    || (
      announcementMessage
      && announcementDate
      && lastDismissalDate
      && (Date.parse(`${announcementDate} +0000`) > lastDismissalDate)
    );
}
