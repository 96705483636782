import '@animoto/components/lib/reset.css';

import {
  Footer,
  SiteNav
} from '@animoto/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import useUser from '../../hooks/useUser';
import logError from '../../utils/logError';
import ExpandableFooter from '../ExpandableFooter';
import GDPR from '../GDPR';
import HeadScripts from '../HeadScripts';
import PageNotification from '../PageNotification';
import PageNotificationProvider from '../PageNotificationProvider';
import SEO from '../SEO';
import {
  body,
  nav,
  wrap
} from './AnimotoLayout.module.css';

export function AnimotoLayout({ SEOInfo, children, topBanner, showSiteNav }) {
  const user = useUser();
  const {
    first_name: firstName,
    last_name: lastName,
    hints_seen_with_data: userHints,
    id,
    isLoading : isLoadingUser
  } = user;

  const isLoggedIn = id !== undefined;

  const shouldShowSiteNav = showSiteNav === null ? true : showSiteNav;

  return (
    <PageNotificationProvider>
      <PageNotification isLoadingUser={isLoadingUser} userHints={userHints} userId={id} />
      <div className={wrap}>
        <HeadScripts />
        <SEO SEOInfo={SEOInfo} />
        {topBanner}
        <GDPR isLoggedIn={isLoggedIn} userHints={userHints} userId={id} />
        { shouldShowSiteNav && (
          <SiteNav className={nav} firstName={firstName} isLoggedIn={isLoggedIn} lastName={lastName} />
        )}
        <div className={body}>
          {children}
        </div>
        <ExpandableFooter />
        <Footer />
      </div>
    </PageNotificationProvider>
  );
}

AnimotoLayout.propTypes = {
  children    : PropTypes.node.isRequired,
  SEOInfo     : PropTypes.object, // eslint-disable-line react/forbid-prop-types
  showSiteNav : PropTypes.bool,
  topBanner   : PropTypes.node
};

AnimotoLayout.defaultProps = {
  SEOInfo     : {},
  showSiteNav : true,
  topBanner   : null
};

export default class AnimotoLayoutSentryWrapped extends Component {
  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo);
  }

  render() {
    const { children, ...restOfProps } = this.props;
    return <AnimotoLayout {...restOfProps}>{children}</AnimotoLayout>;
  }
}

AnimotoLayoutSentryWrapped.propTypes = {
  children : PropTypes.node.isRequired
};
