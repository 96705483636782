/*  eslint-disable max-len */
import { InlineLink, LegalCopy, SecondaryCTAButton } from '@animoto/components';
import savePref from '@animoto/utils/hints';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useEUCheck from '../../hooks/useEUCheck';
import useTermsOfService from '../../hooks/useTermsOfService';
import {
  banner,
  container,
  copy as copyClass,
  hidden
} from './gdpr.module.css';

export default function GDPR({ isLoggedIn, userHints, userId }) {
  const { inConsentRegion, trackingAccepted } = useEUCheck();
  const currentTosVersion = useTermsOfService();

  const [isHidden, setIsHidden] = useState(false);
  const tosUserHint = userHints && userHints.tos_version;
  const hasSeenCurrentTosVersion = tosUserHint && tosUserHint.includes(currentTosVersion);

  const bannerStyles = classNames(banner, {
    [hidden] : isHidden
  });

  /**
   * Pushes the tracking_accepted event to GTM, which enables tracking, hide banner
   */
  const onTrackingAccepted = () => {
    window.dataLayer.push({
      event : 'tracking_accepted'
    });
    setIsHidden(true);
  };

  /**
   * Sets the tos_version user hint to the current version
   */
  const onAcceptLoggedIn = async () => {
    onTrackingAccepted();

    try {
      await savePref(userId, 'tos_version', currentTosVersion);
    } catch (err) {
      // TODO: when we have sentry set up, log error to sentry
      console.log(err);
    }
  };

  /**
   * Sets the gdpr_tracking_accepted cookie
   */
  const onAcceptLoggedOut = () => {
    onTrackingAccepted();
    document.cookie = 'consent=given';
  };

  const showLoggedInBanner = isLoggedIn && !hasSeenCurrentTosVersion;
  const showLoggedOutBanner = !isLoggedIn && !trackingAccepted;

  if (inConsentRegion && (showLoggedInBanner || showLoggedOutBanner)) {
    let ctaButton;
    let copy;

    if (showLoggedInBanner) {
      ctaButton = <SecondaryCTAButton className="s-logged-in-accept" isSmall onClick={onAcceptLoggedIn}>Accept</SecondaryCTAButton>;
      copy = <>We&rsquo;ve recently updated our <InlineLink href="/legal/terms">Terms of Service</InlineLink>. Head over to our <InlineLink className="legal-link link-on-color" href="https://help.animoto.com/hc/en-us/articles/360004421634" rel="noopener noreferrer" target="_blank">Help Center</InlineLink> to learn more.</>;
    }
    if (showLoggedOutBanner) {
      ctaButton = <SecondaryCTAButton className="s-logged-out-accept" isSmall onClick={onAcceptLoggedOut}>Ok</SecondaryCTAButton>;
      copy = <>We use tracking to help us understand how you interact with our website, which helps us improve the Animoto experience. Your information is safe with us. Read our <InlineLink href="/legal/privacy_policy">privacy policy</InlineLink> for more details.</>;
    }

    return (
      <div className={`s-gdpr ${bannerStyles}`}>
        <div className={container}>
          <LegalCopy className={copyClass}>{copy}</LegalCopy>
          {ctaButton}
        </div>
      </div>
    );
  }
  return null;
}

GDPR.propTypes = {
  isLoggedIn : PropTypes.bool.isRequired,
  userHints  : PropTypes.object, // eslint-disable-line react/forbid-prop-types,
  userId     : PropTypes.number
};

GDPR.defaultProps = {
  userHints : {},
  userId    : null
};
