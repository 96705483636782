import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const PageNotificationContext = createContext([
  {
    isOpen  : false,
    message : '',
    type    : ''
  },
  noop
]);

export default function PageNotificationProvider({ children, initialState }) {
  const state = useState(initialState);

  return (
    <PageNotificationContext.Provider value={state}>
      {children}
    </PageNotificationContext.Provider>
  );
}

PageNotificationProvider.propTypes = {
  children     : PropTypes.node.isRequired,
  initialState : PropTypes.shape({
    isOpen  : PropTypes.bool,
    message : PropTypes.string,
    type    : PropTypes.string
  })
};

PageNotificationProvider.defaultProps = {
  initialState : {}
};
