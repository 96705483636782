import { useEffect } from 'react';

import { siteAnnouncementKey } from '../../constants';
import parseSiteAnnouncement from '../../utils/parseSiteAnnouncement';
import showSiteAnnouncement from '../../utils/showSiteAnnouncement';

export default function useSiteNotification({
  siteAnnouncement,
  isLoadingUser,
  closeMessage,
  isOpen,
  setMessage,
  type,
  userHints
}) {
  const { announcementDate, announcementMessage } = parseSiteAnnouncement(siteAnnouncement);

  useEffect(() => {
    if (type === 'notice' && announcementMessage === 'default') {
      closeMessage();
      return;
    }

    const showSiteAnnouncementParams = {
      isLoadingUser,
      announcementMessage,
      siteAnnouncementUserHint : userHints && userHints[siteAnnouncementKey],
      announcementDate
    };
    if (!isOpen && type !== 'notice' && showSiteAnnouncement(showSiteAnnouncementParams)) {
      setMessage({
        isOpen  : true,
        message : announcementMessage,
        type    : 'notice'
      });
    }
  }, [
    isLoadingUser,
    announcementMessage,
    closeMessage,
    announcementDate,
    isOpen,
    setMessage,
    type,
    userHints
  ]);
}
