import evaluateFeature from '@animoto/ld-utils/evaluateFeature';
import { useEffect, useState } from 'react';

/**
 * Returns current terms of service version
 *
 * @returns {String} Terms of service version
 */
export default function useTermsOfService() {
  const [currentTosVersion, setCurrentTosVersion] = useState('');
  useEffect(() => {
    setCurrentTosVersion(evaluateFeature('tos-version', 'latest'));
  }, []);

  return currentTosVersion;
}
