import {
  ExpandableFooterNavigation,
  ExpandableFooterNavigationLinkList,
  InlineLink } from '@animoto/components';
import PropTypes from 'prop-types';
import React from 'react';

import footerLinks from '../utils/footerLinks';

export default function ExpandableFooter({ className }) {
  return (
    <ExpandableFooterNavigation className={className}>
      {footerLinks.map((linkGroup) => (
        <ExpandableFooterNavigationLinkList key={linkGroup.title} title={linkGroup.title}>
          {linkGroup.links.map((link) => (
            <InlineLink key={link.label} href={link.url}>{link.label}</InlineLink>
          ))}
        </ExpandableFooterNavigationLinkList>
      ))}
    </ExpandableFooterNavigation>
  );
}

ExpandableFooter.propTypes = {
  className : PropTypes.string
};

ExpandableFooter.defaultProps = {
  className : ''
};
