export const videoEmbedRegex = /^(?:facebook|youtube|animoto|vimeo):/;

export const instagramEmbedRegex = /^instagram:/;

export const socialMediaEmbedRegex = /class="(?:instagram-media|twitter-tweet)/;

export const animotoPlayPageRegex = /(animoto\.com\/play)\/.+$/;
export const youtubeVideoRegex = /youtube\.com\/(?:watch\?v=|embed\/)([^?=&]+)(?:&list.+)*$/;

export const siteAnnouncementKey = 'site-announcements';
